<style scoped lang="scss">
.table-list {
  min-height: calc(100% - 314px);
  .footer-btns {
    margin-top: 20px;
    padding: 0 44px;
  }
}
</style>

<template>
  <div class="table-list">
    <TablePagination
      :height="tabelHeight"
      :tableData="pageTableData"
      :columnData="tableColumn"
      :total="tabelTotal"
      :currentPage.sync="tabelSearch.currentPage"
      :pageSize.sync="tabelSearch.pageSize"
      selectionShow
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange">
    </TablePagination>
    <div class="footer-btns">
      <el-button class="footer-btn" type="primary" plain @click="cancel">{{$t("cancel")}}</el-button>
      <el-button v-show="stopShow && stopShow2" class="footer-btn" type="primary" @click="stop">{{$t("device.stop")}}</el-button>
      <el-button class="footer-btn" type="primary" @click="confirm" :loading="loading" :disabled="!multipleSelection.length">{{$t("confirm")}}</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { stopScanDevice, getDeviceInfo } from '@/api/device.js'
import { TablePagination } from '@/components'

export default {
  name: 'tabel-list',
  components: {
    TablePagination
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Object,
    tabelData: Array,
    loading: Boolean,
  },
  data() {
    return {
      order: undefined,
      prop: undefined,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
      },
      tableColumn: [
        {
          label: this.$t('device.IpAddress'),
          prop: 'publicIp',
          minWidth: '120',
          sortable: 'custom'
        },
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: '140',
          sortable: 'custom',
          filter: 'toUpperCase'
        },
        {
          label: this.$t('device.model'),
          prop: 'deviceModel',
          minWidth: '100',
          sortable: 'custom'
        },
        {
          label: this.$t('status'),
          prop: 'status',
          minWidth: '100',
          sortable: 'custom'
        },
        {
          label: this.$t('device.firmwareVersion'),
          prop: 'version',
          minWidth: '180',
          sortable: 'custom'
        },
      ],
      multipleSelection: [],
      stopShow2: true
    }
  },
  computed: {
    ...mapGetters('session', ['pageTabelHeight']),
    stopShow() {
      return this.value.scanTotal == this.value.scanTotalNum ? false : true
    },
    newValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      },
    },
    newTableData() {
      const arr = [...this.tabelData]
      if(this.order) {
        arr.sort((a, b) => {
          if(typeof a[this.prop] == 'number') {
            return a[this.prop] - b[this.prop]
          } else {
            var A = a[this.prop].toUpperCase()
            var B = b[this.prop].toUpperCase()
            if (A < B) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
          }
          return 0;
        })
        if(this.order =='desc') arr.reverse()
      }
      return arr
    },
    pageTableData() {
      return this.newTableData.slice(
        this.tabelSearch.pageSize * (this.tabelSearch.currentPage - 1),
        this.tabelSearch.pageSize * this.tabelSearch.currentPage
      )
    },
    tabelTotal() {
      return this.tabelData.length
    },
    tabelHeight() {
      const height = this.pageTabelHeight - 314
      return height < 285 ? 285 : height
    }
  },
  methods: {
    // 多选框变化
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 取消
    cancel() {
      this.$emit('cancel')
    },
    // 确定
    confirm() {
      let params = []
      this.multipleSelection.forEach((e) => {
        params.push({
          deviceModel: e.deviceModel,
          macAddress: e.macAddress,
          publicIp: e.publicIp,
          status: e.status,
          version: e.version,
        })
      })
      getDeviceInfo(params).then(({data}) => {
        this.$message.success(data.message)
        this.$emit('confirm')
      })
    },
    // 停止
    stop() {
      stopScanDevice({
        threadUUID: this.newValue.threadUUID,
      }).then(({data}) => {
        this.$message.success(data.message)
        this.stopShow2 = false
      })
      this.$emit('stop')
    },
    handleSortChange({ order, prop }) {
      this.order = order
      this.prop = prop
    }
  },
}
</script>
