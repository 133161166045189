<style scoped lang="scss">
.scan-log {
  padding: 0 44px;
  .scan-log-title {
    color: #666;
    font-size: 20px;
    line-height: 40px;
  }
  .scan-log-main {
    overflow-x: auto;
    height: 12em;
    border: 1px solid #E5E5E5;
    padding: 20px;
    border-radius: 6px;
    p {
      margin: 0;
      line-height: 1.5em;
    }
  }
  .scan-log-foot {
    color: #666;
    font-size: 16px;
    line-height: 40px;
    span {
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="scan-log">
    <div class="scan-log-title">{{$t('device.scanLog')}}</div>
    <div class="scan-log-main" id="container">
      <p v-for="(item, i) in newValue.logs" :key="i">{{item}}</p>
    </div>
    <div class="scan-log-foot">
      <span>{{ $t('device.scanLog') + ":" + newValue.scanTotal }}</span>
      <span>{{ $t('total') + ": " + newValue.scanTotalNum }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'scan-log',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Object,
  },
  computed: {
    newValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      },
    },
  },
  watch:{
    value:{
      immediate:true,
      deep: true,
      handler: function()  {
        this.onScrollBottom();
      }
    }
  },
  methods:{
    // 滚动到底部
    onScrollBottom () {
      this.$nextTick(() => {
        const container = this.$el.querySelector('#container')
        if (container) container.scrollTop = container.scrollHeight
      })
    }
  }
}
</script>