<style lang='scss' scoped>
@include form-page;
.scan-form {
  margin: 48px auto 20px auto;
  padding-left: 44px;
  padding-right: 44px;
  max-width: 1240px;
  min-height: calc(100% - 426px);
  .item {
    .type {
      width: 100%;
    }
    .ip {
      .el-col {
        position: relative;
        .el-icon-right {
          position: absolute;
          right: -8px;
          top: 18px;
        }
      }
    }
    .el-icon-circle-close {
      cursor: pointer;
      position: absolute;
      right: -36px;
      top: 10px;
      color: #666666;
      font-size: 30px;
    }
  }
}
.scan-form-footer {
  margin: 0 auto;
  padding-left: 44px;
  padding-right: 44px;
  max-width: 1240px;
  text-align: right;
}
</style>

<template>
  <div class="page">
    <div class="page-main">
      <!-- 查询 -->
      <el-form v-if="logTabelData.pageType" class="scan-form" ref="form" :model="form">
        <el-row :gutter="18" v-for="(item, i) in form.dataList" :key="i" class="item">
          <el-col :span="4">
            <div class="el-form-item__label" style="padding-right: 0;">{{typeLabel}}</div>
            <!-- <el-select v-model="item.type" class="type" disabled>
              <el-option :value="0" :label="$t('device.ipAddressRange')"></el-option>
              <el-option :value="1" :label="$t('device.macAddress')"></el-option>
            </el-select> -->
          </el-col>
          <el-col :span="20">
            <div v-if="item.type">
              <el-form-item :prop="`dataList.${i}.macAddress`" :rules="rules.macAddress">
                <el-input
                  v-model="item.macAddress"
                  :placeholder="$t('device.pleaseEnterMacAddress')"
                ></el-input>
              </el-form-item>
            </div>
            <el-row :gutter="24" v-else class="ip">
              <el-col :span="12">
                <el-form-item :prop="`dataList.${i}.startIPAddress`" :rules="rules.startIPAddress">
                  <el-input
                    v-model="item.startIPAddress"
                    :placeholder="$t('device.pleaseEnterIpAddress')"
                  ></el-input>
                </el-form-item>
                <i class="el-icon-right"></i>
              </el-col>
              <el-col :span="12">
                <el-form-item :prop="`dataList.${i}.endIPAddress`" :rules="rules.endIPAddress">
                  <el-input
                    v-model="item.endIPAddress"
                    :placeholder="$t('device.pleaseEnterIpAddress')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-tooltip
            effect="dark"
            :content="$t('delete')"
            placement="top"
            :open-delay="1000"
            v-show="i!=0"
          >
            <i class="el-icon-circle-close" @click="deleteItem(i)"></i>
          </el-tooltip>
        </el-row>
        <el-button type="primary" icon="el-icon-plus" @click="addItem" :disabled="addBtnDisabled">
          {{ $t('add') }}
        </el-button>
      </el-form>
      <div v-if="logTabelData.pageType" class="scan-form-footer">
        <el-button type="primary" @click="submit">
          {{ $t('device.scanDevice') }}
        </el-button>
      </div>
      <tabel-list v-if="!logTabelData.pageType"
        v-model="logTabelData"
        :loading="logTabelData.loading"
        :tabel-data="logTabelData.tabelData"
        @cancel="tabelListCancel"
        @confirm="tabelListConfirm"
        @stop="tabelListStop"
      ></tabel-list>
      <ScanLog v-model="logTabelData" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { scanDevice } from '@/api/device'
import { macFormat, ipFormat } from '@/plugins/methods'
import ScanLog from './components/scan-log.vue'
import TabelList from './components/tabel-list.vue'

export default {
  name: 'ScanDevice',
  components: {
    ScanLog,
    TabelList
  },
  data() {
    var validateIP = (rule, value, callback) => {
      if (value === '') {
        callback(
          new Error(this.$t('device.pleaseEnterIpAddress'))
        )
      } else if (!ipFormat(value)) {
        callback(new Error(this.$t('device.pleaseEnterTheCorrectFormat')))
      } else {
        callback()
      }
    }
    var validateMac = (rule, value, callback) => {
      if (value === '') {
        callback(
          new Error(this.$t('device.pleaseEnterMacAddress'))
        )
      } else if (!macFormat(value)) {
        callback(new Error(this.$t('device.pleaseEnterTheCorrectFormat')))
      } else {
        callback()
      }
    }
    return {
      typeLabel: this.$t('device.ipAddressRange'),
      form: {
        dataList: [
          {
            type: 0,
            startIPAddress: '',
            endIPAddress: '',
            macAddress: '',
          },
        ],
      },
      rules: {
        startIPAddress: {
          validator: validateIP,
          trigger: 'change'
        },
        endIPAddress: {
          validator: validateIP,
          trigger: 'change'
        },
        macAddress: {
          validator: validateMac,
          trigger: 'change'
        },
      },
      logTabelData: {
        loading: false,
        logs: [],
        scanTotal: 0,
        scanTotalNum: 0,
        pageType: true,
        threadUUID: '',
        tabelData: [],
        macAddressList: [],
      }
    }
  },
  computed: {
    ...mapState('account', ['loginInfo']),
    userId() {
      return this.loginInfo.id
    },
    addBtnDisabled() {
      return this.form.dataList.length > 4
    }
  },
  methods: {
    // 新增
    addItem() {
      this.form.dataList.push({
        type: 0,
        startIPAddress: '',
        endIPAddress: '',
        macAddress: '',
      })
    },
    deleteItem(i) {
      if (this.form.dataList.length != 1) {
        this.form.dataList.splice(i, 1)
      }
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let parmas = {
            ipList: [],
            macList: [],
          }
          this.form.dataList.forEach((e) => {
            if (e.type) {
              parmas.macList.push(e.macAddress)
            } else {
              parmas.ipList.push({
                startIPAddress: e.startIPAddress,
                endIPAddress: e.endIPAddress,
              })
            }
          })
          // console.log(parmas)
          scanDevice(parmas).then((res) => {
            // console.log(res);
            if (res.data.code == 0) {
              this.logTabelData.loading = true
              this.logTabelData.scanTotalNum =
                res.data.result.rows[0][1].scanTotalNum
              this.logTabelData.threadUUID = res.data.result.rows[0][0].uuId
              this.logTabelData.pageType = false

              // webscoket start
              let wssName =
                window.location.protocol.indexOf('https') > -1
                  ? 'wss://'
                  : 'ws://'
              let port = window.location.port ? ':' + window.location.port : ''
              let WebSocketUrl =
                wssName + window.location.hostname + port + '/webSocketServer'
                // wssName + '10.4.129.17:8083' + '/webSocketServer'
              this.ws_log = new WebSocket(WebSocketUrl)
              this.ws_log.onopen = () => {
                console.info('已经链接，当前语言：', this.$store.state.memory.language)
                this.ws_log.send(
                  JSON.stringify({
                    userId: this.userId,
                    businessId: 0,
                    resEnv: this.$store.state.memory.language === 'en'
                      ? 'en-US'
                      : 'zh-CN',
                  })
                )
              }
              this.ws_log.onmessage = (e) => {
                // console.info("webSocketServer", e);
                let data = JSON.parse(e.data)
                if (data.deviceInfo) {
                  if(!this.logTabelData.macAddressList.includes(data.deviceInfo.macAddress)) {
                    this.logTabelData.tabelData.unshift(data.deviceInfo)
                    this.logTabelData.macAddressList.unshift(data.deviceInfo.macAddress)
                  }
                }
                if (data.logInfo) {
                  this.logTabelData.logs.push(data.logInfo)
                  if (data.logInfo.indexOf('ip') != -1) {
                    this.logTabelData.scanTotal++ //日志计数
                  }
                }
                if (
                  data.stateFlag == 2 ||
                  this.logTabelData.scanTotal == this.logTabelData.scanTotalNum
                ) {
                  // console.log('close');
                  this.logTabelData.loading = false
                  this.ws_log.close()
                }
              }
              // webscoket end
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    tabelListStop() {
      this.ws_log.close()
      this.logTabelData.loading = false
    },
    tabelListCancel() {
      this.ws_log.close()
      this.logTabelData.loading = false
      this.logTabelData.logs = []
      this.logTabelData.scanTotal = 0
      this.logTabelData.scanTotalNum = 0
      this.logTabelData.pageType = true
      this.logTabelData.tabelData = []
      this.logTabelData.macAddressList = []
    },
    tabelListConfirm() {
      this.ws_log.close()
      this.$router.go(-1)
    },
  }
}
</script>

<style>

</style>